import request from '@/utils/request';
import URL from '@/shared/constant/url';
import { getUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import { apiProxy } from '@/api/proxy/proxy';
import SERVER_TYPES from '@/shared/servertype';
const { project } = URL;

export const getProjectList: (payload: object) => Promise<SuccessResponse<any>> = (payload: object) =>
  request.get(getUrl(URL.project.GET_PROJECT_LIST), {
    params: payload,
  });

export const getAllProjectList: (payload: object) => Promise<SuccessResponse<any>> = (payload: object) =>
  request.get(getUrl(URL.project.GET_ALL_PROJECT_LIST), {
    params: payload,
  });

export const getEnvList: (payload: object) => Promise<SuccessResponse<any>> = (payload: object) =>
  request.get(getUrl(URL.project.GET_ENV_LIST), {
    params: payload,
  });

export const addProject: (payload: object) => Promise<SuccessResponse<any>> = (payload: object) =>
  request.post(getUrl(URL.project.POST_ADD_PROJECT), payload);

export const deleteProjects: (id: any) => Promise<SuccessResponse<any>> = (id: any) =>
  request.post(getUrl(URL.project.DELETE_PROJECT), { id });

// 项目的cos初始化
export const cosInit = (payload: any) =>
  apiProxy(SERVER_TYPES.COSINIT, project.COS_INIT + payload, {
    method: 'GET',
  });
