
import { provide, ref } from 'vue';
import TreeSelector from './components/TreeSelector.vue';
import BasicInfoForm from './components/BasicInfoForm.vue';
import detail from './detail';
import DbConfig from './components/DbConfig.vue';
export default {
  name: 'ProjectDetail',
  props: {
    id: {
      required: true,
    },
  },
  components: { TreeSelector, BasicInfoForm, DbConfig },
  setup(props: any) {
    provide('projectId', props.id);
    const viewDbConfig = ref(false);
    const handleViewDbconfig = () => {
      viewDbConfig.value = !viewDbConfig.value;
    };
    return {
      ...detail(props),
      viewDbConfig,
      handleViewDbconfig,
    };
  },
};
