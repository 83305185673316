import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, _mergeProps({
    modelValue: $setup.ownerIds,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.ownerIds) = $event)),
    multiple: "",
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "multiple-limit": 10,
    placeholder: "请输入关键词",
    "remote-method": $setup.remoteMethod,
    loading: $setup.loading,
    onChange: $setup.onChange
  }, _ctx.$attrs, {
    clearable: "",
    style: {"width":"100%"}
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.owners, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: item.displayName,
          value: item.id
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["modelValue", "remote-method", "loading", "onChange"]))
}