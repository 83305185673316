// 用户状态
export const USER_STATUS: any = {
  '-1': '冻结',
  0: '启用',
};

// 状态码
export enum RES_CODE {
  Success,
}

// 项目负责人角色id
export const PL_ROLE_ID = 6;
// 访客角色id
export const VISITOR_ROLE_ID = 7;
// 项目负责人角色最多可添加人员
export const MAX_USER_COUNT = 10;
