<template>
  <el-form inline label-width="100px" class="config">
    <el-form-item label="数据库URL">
      {{ viewDbConfig ? dbConfig.jdbcUrl : '******' }}
    </el-form-item>
    <el-form-item label="数据库账号">
      {{ viewDbConfig ? dbConfig.username : '******' }}
    </el-form-item>
    <el-form-item label="数据库密码">
      {{ viewDbConfig ? dbConfig.password : '******' }}
    </el-form-item>
  </el-form>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DbConfig',
  props: {
    dbConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    viewDbConfig: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.config {
  overflow: hidden;
  word-break: break-all;
  :deep(.el-form-item) {
    width: 100%;
    display: flex;
  }
}
</style>
