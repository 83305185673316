
/* eslint-disable no-param-reassign */
import _ from 'lodash/fp';
import { computed, inject, ref, Ref, watchEffect, nextTick, getCurrentInstance } from 'vue';
import { updateRoleMembers } from '@/api/project/project';
import { PL_ROLE_ID, MAX_USER_COUNT } from '../utils/constant';

export default {
  name: 'TreeSelector',
  props: {
    option: {
      required: true,
      type: Array,
    },
    optionLabel: {
      required: true,
      type: String,
    },
    optionPlaceholder: {
      required: true,
      type: String,
    },
    role: {
      required: true,
      type: Object,
    },
    checked: {
      required: false,
      type: Array,
      default: () => [],
    },
    notAllow: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  emits: ['userChanged'],
  setup(props: any, context: any) {
    const dialogVisible = ref(false);
    const projectId = inject('projectId') as string;
    const searchStr = ref('');
    const selectedUser: Ref<Array<any>> = ref([]);
    const show = () => {
      dialogVisible.value = true;
    };
    const treeProps = {
      label: 'name',
      children: 'children',
      isLeaf: 'isLeaf',
    };
    const dataDone = ref(true);
    const syncStatus = (node: any) => {
      if (node.parent) {
        node.parent.checked = false;
        node.parent.isIndeterminate = false;
        if (
          _.some((item: any) => item.isIndeterminate || item.checked)(node.parent._children || node.parent.children)
        ) {
          node.parent.isIndeterminate = true;
        }
        if (_.every({ checked: true })(node.parent._children || node.parent.children)) {
          node.parent.checked = true;
          node.parent.isIndeterminate = false;
        }
        if (_.some({ disabled: true, checked: false })(node.parent._children || node.parent.children)) {
          node.parent.disabled = true;
        }
        syncStatus(node.parent);
      }
    };
    const valueLabel = computed(() => `${props.option[0]?.name} - ${props.role?.label}`);
    const setChecked = (treeOption: Array<any>, ids: Array<number>, notAllowIds: Array<number>) => {
      treeOption.forEach((treeNode: any) => {
        if (treeNode.isLeaf) {
          treeNode.isIndeterminate = false;
          treeNode.checked = ids.includes(treeNode.id);
          treeNode.disabled = treeNode.checked;
        } else {
          setChecked(treeNode._children, ids, notAllowIds);
        }
        syncStatus(treeNode);
      });
    };
    let copyOption: any;
    watchEffect(() => {
      dataDone.value = false;
      const selectedUserId = _.map('id')(props.checked);
      const notAllowUserId = _.map('id')(props.notAllow);
      selectedUser.value = [];
      copyOption = _.cloneDeep(props.option);
      setChecked(copyOption, selectedUserId, notAllowUserId);
      nextTick(() => {
        dataDone.value = true;
      });
    });
    const loadNode = (node: any, resolve: Function) => {
      if (node.level === 0) {
        resolve(copyOption);
      } else {
        resolve(node.data._children);
      }
    };
    const checkSingle = (user: any) => {
      const { checked } = user;
      if (checked) {
        selectedUser.value = _.concat(selectedUser.value, user);
      } else {
        selectedUser.value = _.reject({ id: user.id })(selectedUser.value);
      }
      syncStatus(user);
    };
    const checkGroup = async (group: any) => {
      let hasCheckedDisabled = false;
      let hasUncheckedDisabled = false;
      const disabledNodes: any[] = [];
      const getEditableMember = (nodeGroup: any, members: Array<any>) => {
        nodeGroup.forEach((treeNode: any) => {
          if (treeNode.disabled) {
            if (treeNode.checked) {
              hasCheckedDisabled = true;
            } else {
              hasUncheckedDisabled = true;
            }
            disabledNodes.push(treeNode);
            return;
          }
          treeNode.checked = group.checked;
          if (treeNode.isLeaf) {
            members.push(treeNode);
          } else {
            getEditableMember(treeNode.children || treeNode._children, members);
          }
        });
      };
      const groupMembers: any[] = [];
      getEditableMember(group.children || group._children, groupMembers);
      if (!group.checked) {
        selectedUser.value = _.differenceBy('id')(selectedUser.value)(groupMembers);
        group.isIndeterminate = hasCheckedDisabled;
      } else {
        selectedUser.value = _.unionBy('id')(selectedUser.value)(groupMembers);
        group.isIndeterminate = hasUncheckedDisabled;
        group.checked = !hasUncheckedDisabled;
      }
      syncStatus(group);
      disabledNodes.forEach(syncStatus);
    };
    const checkUser = (user: any) => {
      if (!user.isLeaf) {
        checkGroup(user);
      } else {
        checkSingle(user);
      }
    };
    // 获取组件实例
    const instance = getCurrentInstance();
    // 提示信息
    function msgTips(type: string, content: string) {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    }
    const submit = async () => {
      const roleId = props.role.id;
      const userIds = _.map('id')(selectedUser.value.concat(props.checked));
      if (roleId === PL_ROLE_ID && userIds.length > MAX_USER_COUNT) {
        msgTips('warning', '最多只能添加10个项目负责人');
        return;
      }
      const { code } = await updateRoleMembers({
        projectId,
        roleId,
        userIds,
      });
      if (code === 0) {
        dialogVisible.value = false;
        context.emit('userChanged', props.role);
      }
    };
    const cancel = () => {
      searchStr.value = '';
      dialogVisible.value = false;
      context.emit('userChanged', props.role);
    };

    const searchResult: Ref<any> = ref([]);
    const searchDone = ref(true);

    const search = _.debounce(500)(async () => {
      if (!searchStr.value) return;
      const userArr: any[] = [];
      const deptArr: any[] = [];
      const getSearchRes = (searchStr: string, users: any[], depts: any[], nodeList: any[]) => {
        nodeList.forEach((node: any) => {
          if (RegExp(searchStr).test(node.name)) {
            if (node.isLeaf) {
              users.push(node);
            } else {
              depts.push(node);
            }
          }
          node.isLeaf || getSearchRes(searchStr, users, depts, node.children || node._children);
        });
      };
      getSearchRes(searchStr.value, userArr, deptArr, copyOption);
      searchResult.value = [...userArr, ...deptArr];
      searchDone.value = true;
    });
    const clearAndSearch = () => {
      searchDone.value = !searchStr.value;
      searchResult.value = [];
      search();
    };
    const loadSearchNode = (node: any, resolve: Function) => {
      if (node.level === 0) {
        resolve(searchResult.value);
      } else {
        resolve(node.data._children);
      }
    };

    const remove = (user: any) => {
      user.checked = false;
      syncStatus(user);
      selectedUser.value = _.reject({ id: user.id })(selectedUser.value);
    };

    return {
      dialogVisible,
      show,
      loadNode,
      treeProps,
      checkUser,
      selectedUser,
      submit,
      cancel,
      valueLabel,
      searchStr,
      clearAndSearch,
      remove,
      dataDone,
      loadSearchNode,
      searchDone,
    };
  },
};
