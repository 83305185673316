import proxy from '@/shared/constant/url/proxy';
import { SuccessResponse } from '@/types/response';
import request from '@/utils/request';
import { getUrl } from '../utils';

export const apiProxy = (
  module: string,
  action: string[] | string,
  data: any,
  headers?: any,
): Promise<SuccessResponse<any>> => {
  let url = '';
  if (typeof action === 'string') {
    url = `${getUrl(proxy.PROXY)}?module=${module}&action=${action}`;
  } else {
    url = !getUrl(proxy.PROXY).includes('mock')
      ? `${getUrl(proxy.PROXY)}?module=${module}&action=${getUrl(action).replace(/^\//, '')}`
      : getUrl(action);
  }
  return request.post(url, data, {
    headers: {
      'x-sa-proxy': true,
      ...headers,
    },
  });
};
