import axios from '@/utils/request';
import URL from '@/shared/constant/url';
import { apiProxy } from '../proxy/proxy';
import { getUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import SERVER_TYPE from '@/shared/servertype';
const { project } = URL;

export const getProjectList = (payload?: object): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(project.GET_PROJECT_LIST), {
    params: payload,
  });

export const getProjectDetail = (projectId: number | string, params?: any): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(project.GET_PROJECT_DETAIL, String(projectId)), {
    params,
  });

export const updateProject = (projectId: number, payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.UPDATE_PROJECT, String(projectId)), payload);

export const getMemberList = (payload: Record<string, string>): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(project.GET_MEMBER_LIST, payload.projectId));

export const getRoleList = (payload: Record<string, string>): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(project.GET_ROLE_LIST, payload.projectId));

export const updateMembers = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.UPDATE_MEMBER, payload.projectId), payload);

export const deleteMember = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.DELETE_MEMBER, payload.projectId), payload);

export const imgUpload = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.IMAGE_UPLOAD), payload);

export const projectNameTest = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.PROJECT_NAME_TEST), payload);

/**
 * 测试项目数据库自定义配置
 * @param payload
 * @returns
 */
export const testProjectCustomDbConfig = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.TEST_DB_CONFIG), payload);

export const getRoleAuthList = () =>
  apiProxy(SERVER_TYPE.AUTH, project.GET_MODULE_LIST, {
    method: 'get',
  });

export const updateRole = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.UPDATE_ROLE_MODULES, {
    method: 'post',
    data: payload,
  });

export const getAuthByRoleId = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.PROJECT_ROLE_AUTH, {
    method: 'get',
    params: payload,
  });

export const checkRoleRule = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.CHECK_ROLE, {
    method: 'get',
    params: payload,
  });

export const ModRoleName = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.MOD_ROLE, {
    method: 'post',
    data: payload,
  });

export const deleteRole = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.DELETE_ROLE, {
    method: 'post',
    data: payload,
  });

export const addRole = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.ADD_ROLE, {
    method: 'post',
    data: payload,
  });

export const updateRoleMembers = (payload: any) =>
  apiProxy(SERVER_TYPE.AUTH, project.UPDATE_ROLE_MEMBERS, {
    method: 'post',
    data: payload,
  });
export const deleteEnv = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(project.DELETE_ENV), payload);

// 删除其他环境的相同资源
export const delSameResourcesFromEnv = (payload: any) =>
  axios.post(getUrl(project.GET_SAME_RESOURCES_FROM_ENV), payload);
